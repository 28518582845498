<template>
  <div class="banner-area" :class="{hidden: hidden}">
    <div class="banner-fixed-guide">
      <div
        :class="{
          'banner-wrap': true,
          'non-fixed': !fixed()
        }"
      >
        <transition :css="false" @enter="bnrEnter" @leave="bnrLeave" mode="out-in">
          <div ref="banner" :class="{banner: true, 'non-fixed': !fixed()}" v-if="!bannerHidden">
            <div class="inner">
              <p>이모션글로벌과 위대함을 만들어보세요.</p>
              <ul>
                <li>
                  <router-link to="/request">프로젝트 문의</router-link>
                </li>
                <li>
                  <a :href="url" download>회사소개서 다운로드</a>
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
  import {apiFileDown} from "@/api";
  import {TweenMax, Power0} from "gsap";
  import {mapGetters} from "vuex";
  export default {
    name: "AppBanner",
    data() {
      return {
        scene: null,
        bannerHidden: true,
        hidden: false
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"]),
      bnr() {
        return !this.$route.matched[0]?.meta.hiddenBnr;
      },
      url() {
        return `${process.env.VUE_APP_API_URL}/api/v1/company`;
      }
    },

    watch: {
      GE_CONT_HEIGHT() {
        this.init();
      },
      "$route.path"() {
        this.hidden = true;
        this.bannerHidden = true;
        setTimeout(() => {
          this.hidden = false;
        }, 3000);
      }
    },
    mounted() {
      window.addEventListener("scroll", this.bannerHiddenCtr);
      window.addEventListener("resize", this.init);
    },
    methods: {
      async fileDown() {
        try {
          const response = await apiFileDown();
          let blob = new Blob([response.data]);
          let downloadUrl = window.URL.createObjectURL(blob);
          let filename = "";
          let disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
              matches = filenameRegex.exec(disposition);

            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        } catch (e) {
          console.log(e);
        }
      },
      fixed() {
        return this.bnr && this.scene?.state() === "DURING";
      },
      init() {
        const fixedGuide = document.querySelector(".banner-fixed-guide");
        this.scene?.destroy(true);
        const tween = TweenMax.to(".banner-wrap", 1, {
          ease: Power0.easeInOut,
          y: fixedGuide.clientHeight
        });
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: fixedGuide,
            triggerHook: 1,
            duration: fixedGuide.clientHeight
          })
          .setTween(tween);
        this.$scrollmagic.addScene(this.scene);
        this.scene.progress(1);
      },
      bannerHiddenCtr() {
        clearTimeout(this.windowScrollTimer);
        this.bannerHidden = this.fixed();
        this.windowScrollTimer = setTimeout(() => {
          if (window.innerHeight / 2 < window.pageYOffset) {
            this.bannerHidden = false;
          }
        }, 2000);
      },
      bnrEnter(el, done) {
        TweenMax.to(el, this.fixed() ? 0.3 : 0, {
          y: "-100%",
          onComplete: () => {
            done();
          }
        });
      },
      bnrLeave(el, done) {
        TweenMax.to(el, this.fixed() ? 0.3 : 0, {
          y: "0%",
          onComplete: () => {
            done();
          }
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  .hidden {
    visibility: hidden;
  }
  .banner-area {
    height: $bottomBnrHeight;
  }
  .banner-fixed-guide {
    z-index: 10;
    position: absolute;
    width: 100%;
    top: 100vh;
    bottom: $bottomBnrHeight;
    left: 0;
    pointer-events: none;
  }
  .banner-wrap {
    will-change: transform;
    position: relative;
    height: $bottomBnrHeight;
    width: 100%;
    min-width: $globalWidth;
    &.non-fixed {
      overflow: hidden;
    }
    .banner {
      will-change: transform;
      position: relative;
      top: 0;
      width: 100%;
      min-width: $globalWidth;
      height: $bottomBnrHeight;
      background: $red;
      transform: translateY(0);
      pointer-events: auto;
      &.non-fixed {
        top: $bottomBnrHeight;
        overflow: hidden;
      }
      .inner {
        display: flex;
        height: $bottomBnrHeight;
        justify-content: space-between;
        align-items: center;
        @include globalWidth;
        transform: translateX(-16px);
      }
      p {
        font-size: 42px;
        color: #fff;
        font-weight: bold;
      }
      ul {
        display: flex;
        li + li {
          margin-left: 30px;
        }
        a {
          display: inline-block;
          line-height: 50px;
          font-size: 18px;
          color: #fff;
          padding: 0 40px;
          border: 1px solid #fff;
          font-weight: bold;
          border-radius: 26px;
        }
      }
    }
  }
</style>
